import React from 'react';
import { getWeatherIcon, calculateMoonPhase, getMoonPhaseIcon } from '../utils/weatherEmojis';

/**
 * Component to display a 5-day forecast snippet
 * @param {Object} props - Component props
 * @param {Array} props.forecastData - Array of forecast data points
 * @param {Boolean} props.isLoading - Loading state
 * @param {Object} props.error - Error object if any
 */
const ForecastSnippet = ({ forecastData, isLoading, error }) => {
  if (isLoading) return <div className="loading">Loading forecast...</div>;
  if (error) return <div className="error">Error: {error.message}</div>;
  if (!forecastData || !forecastData.list || forecastData.list.length === 0) return null;

  // Group forecast data by day
  const groupedByDay = {};
  
  forecastData.list.forEach(item => {
    const date = new Date(item.dt * 1000).toLocaleDateString('en-US', { weekday: 'short' });
    if (!groupedByDay[date]) {
      groupedByDay[date] = [];
    }
    groupedByDay[date].push(item);
  });

  // Extract the daily data with high/low temperatures
  const dailyData = Object.keys(groupedByDay).map(date => {
    const dayData = groupedByDay[date];
    // Try to get data for noon, or use the first entry if not available
    const noonData = dayData.find(d => {
      const hour = new Date(d.dt * 1000).getHours();
      return hour >= 11 && hour <= 13;
    }) || dayData[0];
    
    // Calculate high and low temperatures for the day
    let highTemp = -Infinity;
    let lowTemp = Infinity;
    
    dayData.forEach(d => {
      if (d.main.temp_max > highTemp) highTemp = d.main.temp_max;
      if (d.main.temp_min < lowTemp) lowTemp = d.main.temp_min;
    });
    
    return {
      day: date,
      temp: Math.round(noonData.main.temp),
      highTemp: Math.round(highTemp),
      lowTemp: Math.round(lowTemp),
      weather: noonData.weather[0],
      dateTime: new Date(noonData.dt * 1000)
    };
  });

  // Only show next 5 days
  const daysToShow = dailyData.slice(0, 5);

  return (
    <div className="forecast-snippet">
      <h4>5-Day Forecast</h4>
      <div className="daily-forecast-container">
        {daysToShow.map((day, index) => (
          <div key={index} className="daily-forecast">
            <div className="forecast-day">{day.day}</div>
            <div className="forecast-icons">
              <div className="weather-icon" title={day.weather.description}>
                {getWeatherIcon(day.weather.main, day.weather.icon)}
              </div>
              <div className="moon-phase-icon" title="Moon phase">
                {getMoonPhaseIcon(calculateMoonPhase(day.dateTime))}
              </div>
            </div>
            <div className="forecast-temps">
              <div className="forecast-temp-range">
                <span className="forecast-high">{day.highTemp}°</span>
                <span className="forecast-divider">/</span>
                <span className="forecast-low">{day.lowTemp}°</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ForecastSnippet;