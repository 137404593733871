// Note: We avoid importing formatUSCityName to prevent circular dependencies

/**
 * Normalizes weather data to a standard format regardless of source
 * 
 * @param {Object} location - Weather data to normalize
 * @returns {Object} Normalized weather data
 */
const normalizeWeatherData = (location) => {
  // Check if the data is from OpenWeatherMap API
  if (location.main && location.weather) {
    return {
      temp: location.main.temp,
      humidity: location.main.humidity || 50, // Default if missing
      windSpeed: location.wind?.speed || 0,
      cloudiness: location.clouds?.all || 0,
      weatherType: location.weather[0]?.main || 'Unknown',
      weatherDescription: location.weather[0]?.description || 'Unknown conditions'
    };
  }
  
  // Handle our simplified similar location structure
  return {
    temp: location.temp,
    humidity: location.humidity || 50, // Default if missing
    windSpeed: location.windSpeed || 0,
    cloudiness: location.cloudiness || 0,
    weatherType: location.condition || 'Unknown',
    weatherDescription: location.condition || 'Unknown conditions'
  };
};

/**
 * Compares weather conditions and returns a similarity score
 * 
 * @param {Object} location1 - First location weather data 
 * @param {Object} location2 - Second location weather data
 * @returns {number} Similarity score (0-100)
 */
export const calculateWeatherSimilarity = (location1, location2) => {
  // Normalize both location data to a standard format
  const normalizedLoc1 = normalizeWeatherData(location1);
  const normalizedLoc2 = normalizeWeatherData(location2);
  
  // Weights for different weather aspects
  const weights = {
    temperature: 0.5,   // 50% of the score
    humidity: 0.2,      // 20% of the score  
    windSpeed: 0.1,     // 10% of the score
    cloudiness: 0.1,    // 10% of the score
    weatherType: 0.1    // 10% of the score
  };
  
  // Calculate temperature similarity (using a bell curve)
  // The closer the temperatures, the higher the score
  const tempDiff = Math.abs(normalizedLoc1.temp - normalizedLoc2.temp);
  const tempSimilarity = Math.exp(-(tempDiff * tempDiff) / 50);
  
  // Calculate humidity similarity
  const humidityDiff = Math.abs(normalizedLoc1.humidity - normalizedLoc2.humidity);
  const humiditySimilarity = 1 - (humidityDiff / 100);
  
  // Calculate wind speed similarity
  const windDiff = Math.abs(normalizedLoc1.windSpeed - normalizedLoc2.windSpeed);
  const maxWindDiff = 20; // Maximum difference to consider
  const windSimilarity = 1 - Math.min(windDiff / maxWindDiff, 1);
  
  // Calculate cloudiness similarity
  const cloudDiff = Math.abs(normalizedLoc1.cloudiness - normalizedLoc2.cloudiness);
  const cloudSimilarity = 1 - (cloudDiff / 100);
  
  // Calculate weather type similarity
  const weatherTypeSimilarity = normalizedLoc1.weatherType === normalizedLoc2.weatherType ? 1 : 0;
  
  // Calculate total similarity score (0-1)
  const similarityScore = (
    weights.temperature * tempSimilarity +
    weights.humidity * humiditySimilarity +
    weights.windSpeed * windSimilarity +
    weights.cloudiness * cloudSimilarity +
    weights.weatherType * weatherTypeSimilarity
  );
  
  // Convert to a percentage (0-100)
  return Math.round(similarityScore * 100);
};

/**
 * Generates a comparison message between two locations
 * 
 * @param {Object} location1 - First location weather data
 * @param {Object} location2 - Second location weather data 
 * @returns {Object} Comparison details
 */
export const generateWeatherComparison = (location1, location2) => {
  const similarity = calculateWeatherSimilarity(location1, location2);
  
  // Normalize both location data to a standard format
  const normalizedLoc1 = normalizeWeatherData(location1);
  const normalizedLoc2 = normalizeWeatherData(location2);
  
  const tempDiff = normalizedLoc2.temp - normalizedLoc1.temp;
  const humidityDiff = normalizedLoc2.humidity - normalizedLoc1.humidity;
  const windDiff = normalizedLoc2.windSpeed - normalizedLoc1.windSpeed;
  
  // Generate comparison text
  const comparison = {
    similarity,
    temperature: {
      difference: tempDiff.toFixed(1),
      message: tempDiff > 0 
        ? `${location2.name} is ${Math.abs(tempDiff).toFixed(1)}°F warmer` 
        : tempDiff < 0 
          ? `${location2.name} is ${Math.abs(tempDiff).toFixed(1)}°F cooler` 
          : `Both locations have the same temperature`
    },
    humidity: {
      difference: humidityDiff,
      message: humidityDiff > 0 
        ? `${location2.name} is ${Math.abs(humidityDiff)}% more humid` 
        : humidityDiff < 0 
          ? `${location2.name} is ${Math.abs(humidityDiff)}% less humid` 
          : `Both locations have the same humidity`
    },
    wind: {
      difference: windDiff.toFixed(1),
      message: windDiff > 0 
        ? `${location2.name} is ${Math.abs(windDiff).toFixed(1)} mph windier` 
        : windDiff < 0 
          ? `${location2.name} is ${Math.abs(windDiff).toFixed(1)} mph less windy` 
          : `Both locations have the same wind speed`
    },
    weatherType: {
      isSame: normalizedLoc1.weatherType === normalizedLoc2.weatherType,
      message: normalizedLoc1.weatherType === normalizedLoc2.weatherType
        ? `Both locations have ${normalizedLoc1.weatherDescription}`
        : `${location1.name} has ${normalizedLoc1.weatherDescription}, while ${location2.name} has ${normalizedLoc2.weatherDescription}`
    }
  };
  
  return comparison;
};