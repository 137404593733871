import React, { useState, useEffect, useRef } from 'react';
import { US_ZIP_CODES, POPULAR_CITIES, ZIP_TO_CITY_STATE, CITY_STATE_LIST, formatUSCityName } from '../services/weatherService';

const SearchForm = ({ onSearch, isLoading, onUseMyLocation }) => {
  const [location, setLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null);
  
  useEffect(() => {
    // Close suggestions when clicking outside
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Update suggestions when location input changes
  useEffect(() => {
    if (!location) {
      setSuggestions([]);
      return;
    }
    
    const query = location.toLowerCase().trim();
    let filteredSuggestions = [];
    
    // Check if input looks like a ZIP code (5 digits or starts with digits)
    const isZipCodeLike = /^\d{1,5}$/.test(query);
    
    if (isZipCodeLike) {
      // Filter zipcodes and include city/state info
      filteredSuggestions = US_ZIP_CODES
        .filter(zip => zip.startsWith(query))
        .slice(0, 6)  // Limit to 6 ZIP suggestions
        .map(zip => ({
          value: zip,
          display: ZIP_TO_CITY_STATE[zip] ? `${zip} - ${ZIP_TO_CITY_STATE[zip]}` : zip,
          type: 'zip'
        }));
    }
    
    // Always add city suggestions (limit based on how many ZIP suggestions we have)
    // First check our comprehensive city/state list
    const cityStateMatches = CITY_STATE_LIST
      .filter(cityState => cityState.toLowerCase().includes(query))
      .slice(0, isZipCodeLike ? 3 : 6) // Fewer city suggestions if we have ZIP codes
      .map(cityState => ({
        value: cityState,
        display: cityState,
        type: 'city'
      }));
    
    // Then add popular cities if needed
    if (cityStateMatches.length < (isZipCodeLike ? 3 : 6)) {
      const maxPopularCities = isZipCodeLike ? 3 - cityStateMatches.length : 6 - cityStateMatches.length;
      
      const popularMatches = POPULAR_CITIES
        .filter(city => city.toLowerCase().includes(query))
        .filter(city => !cityStateMatches.some(cs => cs.display.startsWith(city)))
        .slice(0, maxPopularCities)
        .map(city => ({
          value: city,
          display: formatUSCityName(city) || city,
          type: 'city'
        }));
      
      // Add popular cities to city suggestions
      filteredSuggestions = [...filteredSuggestions, ...cityStateMatches, ...popularMatches];
    } else {
      filteredSuggestions = [...filteredSuggestions, ...cityStateMatches];
    }
    
    // Sort with ZIP codes first if the query looks like a ZIP
    if (isZipCodeLike) {
      filteredSuggestions.sort((a, b) => {
        if (a.type === b.type) return 0;
        return a.type === 'zip' ? -1 : 1;
      });
    }
    
    // Limit to 8 suggestions total
    filteredSuggestions = filteredSuggestions.slice(0, 8);
    
    setSuggestions(filteredSuggestions);
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!location.trim()) return;
    
    // If there's a suggestion selected, use its value
    if (suggestions.length > 0) {
      // Try to find an exact match first
      const exactMatch = suggestions.find(s => s.display === location);
      if (exactMatch) {
        onSearch(exactMatch.value, exactMatch.type);
      } else {
        // Determine if input is likely a ZIP code or city
        const isZipCode = /^\d{5}$/.test(location.trim());
        const searchType = isZipCode ? 'zip' : 'city';
        
        // Just use the first suggestion of the appropriate type if available
        const typedSuggestion = suggestions.find(s => s.type === searchType);
        if (typedSuggestion) {
          onSearch(typedSuggestion.value, typedSuggestion.type);
        } else {
          // Or just the first suggestion regardless of type
          onSearch(suggestions[0].value, suggestions[0].type);
        }
      }
    } else {
      // No suggestions, try to determine type from input format
      const isZipCode = /^\d{5}$/.test(location.trim());
      onSearch(location, isZipCode ? 'zip' : 'city');
    }
    
    setShowSuggestions(false);
  };

  const handleUseMyLocation = () => {
    if (onUseMyLocation) {
      onUseMyLocation();
    }
  };
  
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    setShowSuggestions(true);
  };
  
  const handleSuggestionClick = (suggestion) => {
    setLocation(suggestion.display);
    setShowSuggestions(false);
    // Automatically search when a suggestion is clicked
    onSearch(suggestion.value, suggestion.type);
  };

  return (
    <div className="search-form">
      <form onSubmit={handleSubmit}>
        <div className="search-input-container">
          <div className="search-input-group">
            <input
              type="text"
              placeholder="Enter City Name or Zip Code..."
              value={location}
              onChange={handleLocationChange}
              onFocus={() => setShowSuggestions(true)}
              className="search-input"
            />
            <button 
              type="submit" 
              className="search-button"
              disabled={isLoading}
            >
              {isLoading ? 'Searching...' : 'Get Common Weather'}
            </button>
          </div>
          
          {showSuggestions && suggestions.length > 0 && (
            <div className="suggestions-container" ref={suggestionsRef}>
              <ul className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <li 
                    key={index} 
                    onClick={() => handleSuggestionClick(suggestion)}
                    className={`suggestion-item ${suggestion.type === 'zip' ? 'zip-suggestion' : 'city-suggestion'}`}
                  >
                    {suggestion.display}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </form>
      
{/* Location button removed for simplicity */}
    </div>
  );
};

export default SearchForm;