import axios from 'axios';
// Import the comprehensive list of US zip codes from our data file
import zipCodesData from '../data/us_zip_codes.json';
// Import mapping from zipcode to city, state
import zipToCityState from '../data/zip_to_city_state.json';
// Import city, state list for city search
import cityStateList from '../data/city_state_list.json';

// Use the API key from environment variables
const API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;
const BASE_URL = 'https://api.openweathermap.org/data/2.5';

// Export for use in other components
export const US_ZIP_CODES = zipCodesData;
export const ZIP_TO_CITY_STATE = zipToCityState;
export const CITY_STATE_LIST = cityStateList;

// Extensive list of global cities for typeahead suggestions
export const POPULAR_CITIES = [
  // North America - USA
  "New York", "Los Angeles", "Chicago", "Houston", "Phoenix",
  "Philadelphia", "San Antonio", "San Diego", "Dallas", "San Jose",
  "Austin", "Jacksonville", "Fort Worth", "Columbus", "Indianapolis",
  "Charlotte", "San Francisco", "Seattle", "Denver", "Washington DC",
  "Boston", "El Paso", "Nashville", "Oklahoma City", "Las Vegas",
  "Detroit", "Portland", "Memphis", "Louisville", "Milwaukee",
  "Albuquerque", "Tucson", "Fresno", "Sacramento", "Long Beach",
  "Kansas City", "Mesa", "Atlanta", "Colorado Springs", "Raleigh",
  "Omaha", "Miami", "Oakland", "Minneapolis", "Tulsa", "Cleveland",
  "Wichita", "Arlington", "New Orleans", "Bakersfield", "Tampa",
  "Honolulu", "Aurora", "Anaheim", "Santa Ana", "St. Louis",
  "Pittsburgh", "Corpus Christi", "Riverside", "Cincinnati", "Lexington",
  "Anchorage", "Stockton", "Toledo", "Saint Paul", "Newark", "Greensboro",
  "Buffalo", "Plano", "Lincoln", "Henderson", "Fort Wayne", "Jersey City",
  "St. Petersburg", "Chula Vista", "Orlando", "Norfolk", "Chandler",
  "Laredo", "Madison", "Durham", "Lubbock", "Irvine", "Winston-Salem",
  
  // North America - Canada
  "Toronto", "Montreal", "Vancouver", "Calgary", "Edmonton",
  "Ottawa", "Winnipeg", "Quebec City", "Hamilton", "Halifax",
  "Victoria", "London", "Saskatoon", "Regina", "St. John's",
  
  // North America - Mexico
  "Mexico City", "Guadalajara", "Monterrey", "Puebla", "Tijuana",
  "Juarez", "León", "Zapopan", "Cancun", "Mérida",
  
  // Europe
  "London", "Paris", "Berlin", "Madrid", "Rome",
  "Amsterdam", "Brussels", "Vienna", "Stockholm", "Prague",
  "Barcelona", "Munich", "Copenhagen", "Athens", "Dublin",
  "Warsaw", "Budapest", "Helsinki", "Oslo", "Zurich",
  "Lisbon", "Manchester", "Birmingham", "Seville", "Frankfurt",
  "Milan", "Venice", "Hamburg", "Glasgow", "Edinburgh",
  "Lyon", "Naples", "Rotterdam", "Krakow", "Marseille", 
  "Florence", "Valencia", "Bordeaux", "Gothenburg", "Reykjavik",
  "Monaco", "Geneva", "Bratislava", "Luxembourg", "Düsseldorf",
  "Edinburgh", "Belfast", "Cologne", "Stuttgart", "Porto",
  
  // Asia
  "Tokyo", "Delhi", "Shanghai", "Beijing", "Mumbai",
  "Osaka", "Bangkok", "Singapore", "Seoul", "Kuala Lumpur",
  "Jakarta", "Hong Kong", "Manila", "Taipei", "Karachi",
  "Dhaka", "Yangon", "Ho Chi Minh City", "Hanoi", "Baghdad",
  "Riyadh", "Dubai", "Abu Dhabi", "Doha", "Astana",
  "Kyoto", "Sapporo", "Chengdu", "Guangzhou", "Shenzhen",
  "Istanbul", "Damascus", "Tehran", "Jerusalem", "Tel Aviv",
  "Ankara", "Beirut", "Jeddah", "Mecca", "Kolkata",
  "Chennai", "Bengaluru", "Hyderabad", "Lahore", "Almaty",
  "Ulaanbaatar", "Pyongyang", "Kabul", "Kathmandu", "Phnom Penh",
  
  // Africa
  "Cairo", "Lagos", "Kinshasa", "Johannesburg", "Nairobi",
  "Casablanca", "Cape Town", "Dar es Salaam", "Algiers", "Addis Ababa",
  "Accra", "Dakar", "Khartoum", "Rabat", "Tunis",
  "Harare", "Abidjan", "Durban", "Lusaka", "Pretoria",
  "Abuja", "Marrakesh", "Alexandria", "Luanda", "Antananarivo",
  
  // South America
  "São Paulo", "Lima", "Bogotá", "Rio de Janeiro", "Santiago",
  "Caracas", "Buenos Aires", "Salvador", "Brasília", "Medellín",
  "Quito", "Montevideo", "Asunción", "La Paz", "Guayaquil",
  "Cali", "Córdoba", "Recife", "Curitiba", "Barranquilla",
  
  // Oceania
  "Sydney", "Melbourne", "Brisbane", "Perth", "Auckland",
  "Adelaide", "Wellington", "Christchurch", "Gold Coast", "Canberra",
  "Port Moresby", "Suva", "Hobart", "Darwin", "Cairns"
];

/**
 * Get current weather by zip code (US only)
 * @param {string} zipCode - US zip code
 * @param {string} units - Units of measurement (imperial, metric, standard)
 * @returns {Promise} Weather data
 */
export const getWeatherByZip = async (zipCode, units = 'imperial') => {
  try {
    // Extract just the 5-digit zip code if it contains city/state info
    // For example, from "12303 - Schenectady, NY" we extract "12303"
    const cleanZip = zipCode.split(' ')[0].trim();
    
    const response = await axios.get(`${BASE_URL}/weather`, {
      params: {
        zip: `${cleanZip},us`,
        appid: API_KEY,
        units
      }
    });
    
    // If we have city-state info for this zip code, attach it to the response
    if (ZIP_TO_CITY_STATE[cleanZip]) {
      const cityState = ZIP_TO_CITY_STATE[cleanZip];
      response.data.formattedLocation = cityState;
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching weather data:', error);
    throw error;
  }
};

/**
 * Get current weather by city name
 * @param {string} cityName - City name
 * @param {string} units - Units of measurement (imperial, metric, standard)
 * @returns {Promise} Weather data
 */
export const getWeatherByCity = async (cityName, units = 'imperial') => {
  try {
    // Handle special case for Washington DC
    let searchQuery = cityName;
    
    // Format city name for API - handle special cases
    if (cityName.includes('Washington, DC') || cityName.includes('Washington, D.C.')) {
      searchQuery = 'Washington,US';
    } 
    // If city has format "City, ST" format it properly for the API
    else if (cityName.includes(',') && cityName.split(',')[1].trim().length === 2) {
      const [city, state] = cityName.split(',').map(part => part.trim());
      searchQuery = `${city},US`;
    }
    
    const response = await axios.get(`${BASE_URL}/weather`, {
      params: {
        q: searchQuery,
        appid: API_KEY,
        units
      }
    });
    
    // Store the original searched name to preserve formatting
    response.data.formattedLocation = cityName;
    
    // If it's a US city and we don't already have a formatted name
    if (response.data.sys && response.data.sys.country === 'US' && !cityName.includes(',')) {
      response.data.formattedLocation = formatUSCityName(response.data.name);
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching weather data:', error);
    throw error;
  }
};

/**
 * Get current weather by geographic coordinates
 * @param {number} lat - Latitude
 * @param {number} lon - Longitude
 * @param {string} units - Units of measurement (imperial, metric, standard)
 * @returns {Promise} Weather data
 */
export const getWeatherByCoords = async (lat, lon, units = 'imperial') => {
  try {
    const response = await axios.get(`${BASE_URL}/weather`, {
      params: {
        lat,
        lon,
        appid: API_KEY,
        units
      }
    });
    
    // If it's a US city, add formatted city name
    if (response.data.sys && response.data.sys.country === 'US') {
      response.data.formattedLocation = formatUSCityName(response.data.name);
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching weather data:', error);
    throw error;
  }
};

/**
 * Get 5-day weather forecast by geographic coordinates
 * @param {number} lat - Latitude
 * @param {number} lon - Longitude
 * @param {string} units - Units of measurement (imperial, metric, standard)
 * @returns {Promise} Forecast data
 */
export const getForecastByCoords = async (lat, lon, units = 'imperial') => {
  try {
    const response = await axios.get(`${BASE_URL}/forecast`, {
      params: {
        lat,
        lon,
        appid: API_KEY,
        units
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast data:', error);
    throw error;
  }
};

/**
 * Get 5-day weather forecast by city name
 * @param {string} cityName - City name
 * @param {string} units - Units of measurement (imperial, metric, standard)
 * @returns {Promise} Forecast data
 */
export const getForecastByCity = async (cityName, units = 'imperial') => {
  try {
    const response = await axios.get(`${BASE_URL}/forecast`, {
      params: {
        q: cityName,
        appid: API_KEY,
        units
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching forecast data:', error);
    throw error;
  }
};

/**
 * Find locations with similar current weather conditions
 * @param {number} temp - Temperature to match
 * @param {string} weatherCondition - Weather condition code
 * @returns {Promise} Array of locations with similar weather
 */
export const findSimilarWeatherLocations = async (temp, weatherCondition) => {
  // We'll use real API data for similar weather locations
  // Choose some diverse global cities to check for similar weather
  const citiesToCheck = [
    "Buenos Aires,AR",
    "Rio de Janeiro,BR",
    "Sydney,AU",
    "Melbourne,AU",
    "Tokyo,JP",
    "Seoul,KR",
    "Bangkok,TH",
    "Singapore,SG",
    "Cape Town,ZA",
    "Madrid,ES",
    "Barcelona,ES",
    "Rome,IT",
    "Berlin,DE",
    "Paris,FR",
    "London,GB",
    "Vancouver,CA",
    "Toronto,CA",
    "Mexico City,MX"
  ];
  
  // Get real weather data for these cities
  const promises = citiesToCheck.map(city => getWeatherByCity(city));
  const results = await Promise.allSettled(promises);
  
  // Filter for cities with similar weather (within 10°F of searched location)
  const similarWeatherCities = results
    .filter(result => result.status === 'fulfilled')
    .map(result => result.value)
    .filter(cityData => {
      const tempDiff = Math.abs(cityData.main.temp - temp);
      // Consider similar if within 10°F and similar weather condition
      return tempDiff <= 10 && (
        cityData.weather[0].main.toLowerCase() === weatherCondition.toLowerCase() ||
        (cityData.weather[0].main.toLowerCase().includes('cloud') && weatherCondition.toLowerCase().includes('cloud'))
      );
    })
    .slice(0, 3); // Take up to 3 matches
  
  // If we don't have enough similar cities, add some cities with similar temps
  if (similarWeatherCities.length < 3) {
    const similarTempCities = results
      .filter(result => result.status === 'fulfilled')
      .map(result => result.value)
      .filter(cityData => {
        const tempDiff = Math.abs(cityData.main.temp - temp);
        // Consider similar if within 8°F but weather might differ
        return tempDiff <= 8 && !similarWeatherCities.includes(cityData);
      })
      .slice(0, 3 - similarWeatherCities.length); // Take only what we need
    
    similarWeatherCities.push(...similarTempCities);
  }
  
  // If we still don't have 3 cities, just grab the closest matches
  if (similarWeatherCities.length < 3) {
    const remainingCities = results
      .filter(result => result.status === 'fulfilled')
      .map(result => result.value)
      .filter(cityData => !similarWeatherCities.includes(cityData))
      .sort((a, b) => {
        const diffA = Math.abs(a.main.temp - temp);
        const diffB = Math.abs(b.main.temp - temp);
        return diffA - diffB; // Sort by temperature difference
      })
      .slice(0, 3 - similarWeatherCities.length);
      
    similarWeatherCities.push(...remainingCities);
  }
  
  return similarWeatherCities;
};

/**
 * Find locations with opposite weather conditions
 * @param {number} temp - Temperature to invert
 * @param {string} weatherCondition - Weather condition to find opposite for
 * @returns {Promise} Array of locations with opposite weather
 */
export const findOppositeWeatherLocations = async (temp, weatherCondition) => {
  // We'll use real API data for opposite weather locations
  // Choose some diverse global cities to check for opposite weather
  const citiesToCheck = [
    "Reykjavik,IS",
    "Oslo,NO",
    "Stockholm,SE",
    "Helsinki,FI",
    "Dubai,AE",
    "Cairo,EG",
    "Riyadh,SA",
    "Doha,QA",
    "Wellington,NZ",
    "Auckland,NZ",
    "Jakarta,ID",
    "Manila,PH",
    "La Paz,BO",
    "Quito,EC",
    "Lima,PE",
    "Santiago,CL",
    "Anchorage,US",
    "Miami,US",
    "Denver,US",
    "Moscow,RU"
  ];
  
  // Get real weather data for these cities
  const promises = citiesToCheck.map(city => getWeatherByCity(city));
  const results = await Promise.allSettled(promises);
  
  // Filter for cities with opposite weather (at least 20°F different and different conditions)
  const oppositeWeatherCities = results
    .filter(result => result.status === 'fulfilled')
    .map(result => result.value)
    .filter(cityData => {
      const tempDiff = Math.abs(cityData.main.temp - temp);
      // Consider opposite if at least 20°F different
      return tempDiff >= 20 && cityData.weather[0].main.toLowerCase() !== weatherCondition.toLowerCase();
    })
    .sort((a, b) => {
      // Sort by temperature difference (largest first)
      const diffA = Math.abs(a.main.temp - temp);
      const diffB = Math.abs(b.main.temp - temp);
      return diffB - diffA;
    })
    .slice(0, 3); // Take the 3 most opposite
  
  // If we don't have enough, add cities with just different temperatures
  if (oppositeWeatherCities.length < 3) {
    const differentTempCities = results
      .filter(result => result.status === 'fulfilled')
      .map(result => result.value)
      .filter(cityData => {
        const tempDiff = Math.abs(cityData.main.temp - temp);
        // At least 15°F different
        return tempDiff >= 15 && !oppositeWeatherCities.includes(cityData);
      })
      .sort((a, b) => {
        const diffA = Math.abs(a.main.temp - temp);
        const diffB = Math.abs(b.main.temp - temp);
        return diffB - diffA; // Sort by temperature difference
      })
      .slice(0, 3 - oppositeWeatherCities.length);
    
    oppositeWeatherCities.push(...differentTempCities);
  }
  
  // If we still don't have enough, just take any remaining cities
  if (oppositeWeatherCities.length < 3) {
    const remainingCities = results
      .filter(result => result.status === 'fulfilled')
      .map(result => result.value)
      .filter(cityData => !oppositeWeatherCities.includes(cityData))
      .slice(0, 3 - oppositeWeatherCities.length);
    
    oppositeWeatherCities.push(...remainingCities);
  }
  
  return oppositeWeatherCities;
};

/**
 * Get a random location with similar or opposite weather
 * @param {number} temp - Base temperature
 * @param {string} weatherCondition - Base weather condition
 * @param {boolean} isSimilar - Whether to find similar (true) or opposite (false) weather
 * @returns {Object} Location with requested weather profile
 */
const getRandomLocationWithWeather = async (temp, weatherCondition, isSimilar) => {
  // For similar locations, we want similar temp and condition
  // For opposite locations, we want significantly different temp and opposite condition
  const oppositeTemp = isSimilar ? temp : getOppositeTemperature(temp);
  
  // Determine opposite weather condition
  const oppositeCondition = isSimilar ? weatherCondition : getOppositeWeatherCondition(weatherCondition);
  
  // For similar weather, allow ±5°F; for opposite, enforce at least 25°F difference
  const baseTemp = isSimilar ? temp : oppositeTemp;
  
  // Keep temperature variations minimal to avoid unrealistic values
  const tempVariationRange = isSimilar ? [-5, 5] : [-5, 5];
  
  // Much larger array of possible locations with various weather
  const possibleLocations = [
    // Asia
    {
      name: "Tokyo, Japan",
      region: "Asia",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 65 + getRandomVariation(10),
      windSpeed: 4.5 + getRandomVariation(2),
      cloudiness: 40 + getRandomVariation(15),
      lat: 35.6762,
      lon: 139.6503,
      timezone: 32400 // UTC+9
    },
    {
      name: "Bangkok, Thailand",
      region: "Asia",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 75 + getRandomVariation(10),
      windSpeed: 3.8 + getRandomVariation(2),
      cloudiness: 35 + getRandomVariation(15),
      lat: 13.7563,
      lon: 100.5018,
      timezone: 25200 // UTC+7
    },
    {
      name: "Seoul, South Korea",
      region: "Asia",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 60 + getRandomVariation(10),
      windSpeed: 5.2 + getRandomVariation(2),
      cloudiness: 30 + getRandomVariation(15),
      lat: 37.5665,
      lon: 126.9780,
      timezone: 32400 // UTC+9
    },
    {
      name: "Mumbai, India",
      region: "Asia",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 80 + getRandomVariation(10),
      windSpeed: 7.3 + getRandomVariation(2),
      cloudiness: 25 + getRandomVariation(15),
      lat: 19.0760,
      lon: 72.8777,
      timezone: 19800 // UTC+5:30
    },
    
    // Europe
    {
      name: "Madrid, Spain",
      region: "Europe",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 55 + getRandomVariation(10),
      windSpeed: 3.2 + getRandomVariation(2),
      cloudiness: 20 + getRandomVariation(15),
      lat: 40.4168,
      lon: -3.7038,
      timezone: 3600 // UTC+1
    },
    {
      name: "Rome, Italy",
      region: "Europe",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 62 + getRandomVariation(10),
      windSpeed: 4.1 + getRandomVariation(2),
      cloudiness: 22 + getRandomVariation(15),
      lat: 41.9028,
      lon: 12.4964,
      timezone: 3600 // UTC+1
    },
    {
      name: "Berlin, Germany",
      region: "Europe",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 68 + getRandomVariation(10),
      windSpeed: 6.2 + getRandomVariation(2),
      cloudiness: 45 + getRandomVariation(15),
      lat: 52.5200,
      lon: 13.4050,
      timezone: 3600 // UTC+1
    },
    {
      name: "Reykjavik, Iceland",
      region: "Europe",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 80 + getRandomVariation(10),
      windSpeed: 12.5 + getRandomVariation(2),
      cloudiness: 75 + getRandomVariation(15),
      lat: 64.1466,
      lon: -21.9426,
      timezone: 0 // UTC+0
    },
    {
      name: "Stockholm, Sweden",
      region: "Europe",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 60 + getRandomVariation(10),
      windSpeed: 8.7 + getRandomVariation(2),
      cloudiness: 50 + getRandomVariation(15),
      lat: 59.3293,
      lon: 18.0686,
      timezone: 3600 // UTC+1
    },
    
    // Africa
    {
      name: "Cape Town, South Africa",
      region: "Africa",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 72 + getRandomVariation(10),
      windSpeed: 8.1 + getRandomVariation(2),
      cloudiness: 10 + getRandomVariation(15),
      lat: -33.9249,
      lon: 18.4241,
      timezone: 7200 // UTC+2
    },
    {
      name: "Cairo, Egypt",
      region: "Africa",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 45 + getRandomVariation(10),
      windSpeed: 6.7 + getRandomVariation(2),
      cloudiness: 5 + getRandomVariation(15),
      lat: 30.0444,
      lon: 31.2357,
      timezone: 7200 // UTC+2
    },
    {
      name: "Nairobi, Kenya",
      region: "Africa", 
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 65 + getRandomVariation(10),
      windSpeed: 5.3 + getRandomVariation(2),
      cloudiness: 30 + getRandomVariation(15),
      lat: -1.2921,
      lon: 36.8219,
      timezone: 10800 // UTC+3
    },
    
    // Oceania
    {
      name: "Sydney, Australia",
      region: "Oceania",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 68 + getRandomVariation(10),
      windSpeed: 5.4 + getRandomVariation(2),
      cloudiness: 30 + getRandomVariation(15),
      lat: -33.8688,
      lon: 151.2093,
      timezone: 36000 // UTC+10
    },
    {
      name: "Auckland, New Zealand",
      region: "Oceania",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 70 + getRandomVariation(10),
      windSpeed: 10.3 + getRandomVariation(2),
      cloudiness: 35 + getRandomVariation(15),
      lat: -36.8485,
      lon: 174.7633,
      timezone: 43200 // UTC+12
    },
    {
      name: "Port Moresby, Papua New Guinea",
      region: "Oceania",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 78 + getRandomVariation(10),
      windSpeed: 4.8 + getRandomVariation(2),
      cloudiness: 40 + getRandomVariation(15),
      lat: -9.4438,
      lon: 147.1803,
      timezone: 36000 // UTC+10
    },
    
    // North America
    {
      name: "Vancouver, Canada",
      region: "North America",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 62 + getRandomVariation(10),
      windSpeed: 7.2 + getRandomVariation(2),
      cloudiness: 45 + getRandomVariation(15),
      lat: 49.2827,
      lon: -123.1207,
      timezone: -28800 // UTC-8
    },
    {
      name: "Mexico City, Mexico",
      region: "North America",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 55 + getRandomVariation(10),
      windSpeed: 3.9 + getRandomVariation(2),
      cloudiness: 25 + getRandomVariation(15),
      lat: 19.4326,
      lon: -99.1332,
      timezone: -21600 // UTC-6
    },
    {
      name: "Chicago",
      region: "North America",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 58 + getRandomVariation(10),
      windSpeed: 9.2 + getRandomVariation(2),
      cloudiness: 35 + getRandomVariation(15),
      lat: 41.8781,
      lon: -87.6298,
      timezone: -21600, // UTC-6
      country: "US"
    },
    {
      name: "Honolulu",
      region: "North America",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 68 + getRandomVariation(10),
      windSpeed: 6.3 + getRandomVariation(2),
      cloudiness: 20 + getRandomVariation(15),
      lat: 21.3069,
      lon: -157.8583,
      timezone: -36000, // UTC-10
      country: "US"
    },
    
    // South America
    {
      name: "Rio de Janeiro, Brazil",
      region: "South America",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 75 + getRandomVariation(10),
      windSpeed: 6.8 + getRandomVariation(2),
      cloudiness: 25 + getRandomVariation(15),
      lat: -22.9068,
      lon: -43.1729,
      timezone: -10800 // UTC-3
    },
    {
      name: "Buenos Aires, Argentina",
      region: "South America",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 65 + getRandomVariation(10),
      windSpeed: 8.4 + getRandomVariation(2),
      cloudiness: 30 + getRandomVariation(15),
      lat: -34.6037,
      lon: -58.3816,
      timezone: -10800 // UTC-3
    },
    {
      name: "Lima, Peru",
      region: "South America",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 82 + getRandomVariation(10),
      windSpeed: 5.7 + getRandomVariation(2),
      cloudiness: 55 + getRandomVariation(15),
      lat: -12.0464,
      lon: -77.0428,
      timezone: -18000 // UTC-5
    },
    
    // Middle East
    {
      name: "Dubai, UAE",
      region: "Middle East",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 50 + getRandomVariation(10),
      windSpeed: 9.5 + getRandomVariation(2),
      cloudiness: 5 + getRandomVariation(15),
      lat: 25.2048,
      lon: 55.2708,
      timezone: 14400 // UTC+4
    },
    {
      name: "Istanbul, Turkey",
      region: "Middle East",
      temp: temp + getRandomTempVariation(),
      condition: weatherCondition,
      humidity: 63 + getRandomVariation(10),
      windSpeed: 7.6 + getRandomVariation(2),
      cloudiness: 40 + getRandomVariation(15),
      lat: 41.0082,
      lon: 28.9784,
      timezone: 10800 // UTC+3
    }
  ];

  // Helper function for random temperature variations
  function getRandomTempVariation() {
    const min = tempVariationRange[0];
    const max = tempVariationRange[1];
    // Generate random number between min and max
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Helper function for random variations in other weather metrics
  function getRandomVariation(max) {
    return Math.floor(Math.random() * max) - Math.floor(max / 2);
  }

  // Get one location from each of two different regions (ensures diversity)
  // First, categorize locations by region
  const locationsByRegion = {};
  possibleLocations.forEach(location => {
    if (!locationsByRegion[location.region]) {
      locationsByRegion[location.region] = [];
    }
    locationsByRegion[location.region].push(location);
  });
  
  // Get list of regions
  const regions = Object.keys(locationsByRegion);
  
  // Randomly select two different regions
  const shuffledRegions = [...regions].sort(() => 0.5 - Math.random());
  const selectedRegions = shuffledRegions.slice(0, 2);
  
  // For a single location approach, pick one region randomly
  const randomRegionIndex = Math.floor(Math.random() * regions.length);
  const selectedRegion = regions[randomRegionIndex];
  
  // Get cities from that region
  const citiesInRegion = locationsByRegion[selectedRegion];
  const randomCityIndex = Math.floor(Math.random() * citiesInRegion.length);
  const selectedCity = citiesInRegion[randomCityIndex];
  
  // Format US city names
  let cityName = selectedCity.name;
  if (selectedCity.country === "US") {
    // Apply proper US city formatting with state abbreviation
    cityName = formatUSCityName(cityName);
  } else if (cityName.includes(", USA")) {
    // Handle any legacy data with USA suffix
    const baseName = cityName.replace(", USA", "");
    cityName = formatUSCityName(baseName);
  }
  
  // Modify the location to have the requested weather
  const result = {
    ...selectedCity,
    name: cityName,
    temp: baseTemp + getRandomTempVariation(),
    condition: oppositeCondition,
    type: isSimilar ? 'similar' : 'opposite'
  };

  // If it's a US city, add formattedLocation field for consistency with API responses
  if (selectedCity.country === "US") {
    result.formattedLocation = cityName;
  }

  return result;
};

/**
 * Get user's current location weather
 * @param {string} units - Units of measurement (imperial, metric, standard)
 * @returns {Promise} Weather data
 */
export const getCurrentLocationWeather = async (units = 'imperial') => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'));
      return;
    }
    
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const { latitude, longitude } = position.coords;
          const weatherData = await getWeatherByCoords(latitude, longitude, units);
          
          // If it's a US city, add formatted city name
          if (weatherData.sys && weatherData.sys.country === 'US') {
            weatherData.formattedLocation = formatUSCityName(weatherData.name);
          }
          
          resolve(weatherData);
        } catch (error) {
          reject(error);
        }
      },
      (error) => {
        reject(new Error(`Geolocation error: ${error.message}`));
      }
    );
  });
};

/**
 * Calculate the "opposite" temperature with minimum 25°F difference
 * @param {number} temp - Original temperature in Fahrenheit
 * @returns {number} Opposite temperature
 */
const getOppositeTemperature = (temp) => {
  // For temperature, we'll consider opposite to be roughly inverted around 
  // an average comfortable temperature of ~70°F
  const avgTemp = 70; 
  const distanceFromAvg = avgTemp - temp;
  
  // Apply the distance in the opposite direction
  let oppositeTemp = avgTemp + distanceFromAvg;
  
  // Ensure there's at least a 25°F difference
  const tempDifference = Math.abs(oppositeTemp - temp);
  
  if (tempDifference < 25) {
    // Not enough contrast, so increase the difference
    if (oppositeTemp > temp) {
      // If opposite is warmer, make it even warmer
      oppositeTemp = temp + 25;
    } else {
      // If opposite is colder, make it even colder
      oppositeTemp = temp - 25;
    }
  }
  
  return oppositeTemp;
};

/**
 * Get the opposite weather condition
 * @param {string} condition - Weather condition
 * @returns {string} Opposite condition
 */
const getOppositeWeatherCondition = (condition) => {
  const conditionLower = condition.toLowerCase();
  
  // Map of weather condition opposites
  const oppositeMap = {
    'clear': 'Clouds',
    'clouds': 'Clear',
    'rain': 'Clear',
    'drizzle': 'Clear',
    'thunderstorm': 'Clear',
    'snow': 'Clear',
    'mist': 'Clear',
    'smoke': 'Clear',
    'haze': 'Clear',
    'dust': 'Rain',
    'fog': 'Clear',
    'sand': 'Rain',
    'ash': 'Rain',
    'squall': 'Clear',
    'tornado': 'Clear'
  };
  
  // Look up the opposite, or default to "Clear" if not found
  for (const [key, value] of Object.entries(oppositeMap)) {
    if (conditionLower.includes(key)) {
      return value;
    }
  }
  
  return 'Clear'; // Default opposite
};

/**
 * Format US city name to include state
 * @param {string} cityName - City name to format
 * @returns {string} Formatted name with state
 */
export const formatUSCityName = (cityName) => {
  // Map of US cities to their state abbreviations
  const usCityToStateMap = {
    "New York": "NY",
    "Los Angeles": "CA",
    "Chicago": "IL",
    "Houston": "TX",
    "Phoenix": "AZ",
    "Philadelphia": "PA",
    "San Antonio": "TX",
    "San Diego": "CA",
    "Dallas": "TX",
    "San Jose": "CA",
    "Austin": "TX",
    "Jacksonville": "FL",
    "Fort Worth": "TX",
    "Columbus": "OH",
    "Indianapolis": "IN",
    "Charlotte": "NC",
    "San Francisco": "CA",
    "Seattle": "WA",
    "Denver": "CO",
    "Washington": "DC",
    "Washington DC": "DC",
    "Washington D.C.": "DC",
    "Boston": "MA",
    "El Paso": "TX",
    "Nashville": "TN",
    "Oklahoma City": "OK",
    "Las Vegas": "NV",
    "Detroit": "MI",
    "Portland": "OR",
    "Memphis": "TN",
    "Louisville": "KY",
    "Milwaukee": "WI",
    "Albuquerque": "NM",
    "Tucson": "AZ",
    "Fresno": "CA",
    "Sacramento": "CA",
    "Long Beach": "CA",
    "Kansas City": "MO",
    "Mesa": "AZ",
    "Atlanta": "GA",
    "Colorado Springs": "CO",
    "Raleigh": "NC",
    "Omaha": "NE",
    "Miami": "FL",
    "Oakland": "CA",
    "Minneapolis": "MN",
    "Tulsa": "OK",
    "Cleveland": "OH",
    "Wichita": "KS",
    "Arlington": "TX",
    "New Orleans": "LA",
    "Bakersfield": "CA",
    "Tampa": "FL",
    "Honolulu": "HI",
    "Aurora": "CO",
    "Anaheim": "CA",
    "Santa Ana": "CA",
    "St. Louis": "MO",
    "Pittsburgh": "PA",
    "Corpus Christi": "TX",
    "Riverside": "CA",
    "Cincinnati": "OH",
    "Lexington": "KY",
    "Anchorage": "AK",
    "Stockton": "CA",
    "Toledo": "OH",
    "Saint Paul": "MN",
    "Newark": "NJ",
    "Greensboro": "NC",
    "Buffalo": "NY",
    "Plano": "TX",
    "Lincoln": "NE",
    "Henderson": "NV",
    "Fort Wayne": "IN",
    "Jersey City": "NJ",
    "St. Petersburg": "FL",
    "Chula Vista": "CA",
    "Orlando": "FL",
    "Norfolk": "VA",
    "Chandler": "AZ",
    "Laredo": "TX",
    "Madison": "WI",
    "Durham": "NC",
    "Lubbock": "TX",
    "Irvine": "CA",
    "Winston-Salem": "NC",
    // Additional cities
    "Albany": "NY",
    "Allentown": "PA",
    "Amarillo": "TX",
    "Ann Arbor": "MI",
    "Asheville": "NC",
    "Athens": "GA",
    "Augusta": "GA",
    "Baton Rouge": "LA",
    "Billings": "MT",
    "Birmingham": "AL",
    "Boise": "ID",
    "Boulder": "CO",
    "Bridgeport": "CT",
    "Burlington": "VT",
    "Cambridge": "MA",
    "Cedar Rapids": "IA",
    "Charleston": "SC",
    "Chattanooga": "TN",
    "Chesapeake": "VA",
    "Chicago": "IL",
    "Cheyenne": "WY",
    "Columbia": "SC",
    "Columbus": "GA",
    "Concord": "NH",
    "Dallas": "TX",
    "Davenport": "IA",
    "Dayton": "OH",
    "Denton": "TX",
    "Des Moines": "IA",
    "Eugene": "OR",
    "Evansville": "IN",
    "Everett": "WA",
    "Fargo": "ND",
    "Fayetteville": "AR",
    "Flint": "MI",
    "Fort Collins": "CO",
    "Fort Lauderdale": "FL",
    "Fremont": "CA",
    "Gainesville": "FL",
    "Gilbert": "AZ",
    "Glendale": "AZ",
    "Grand Rapids": "MI",
    "Green Bay": "WI",
    "Greensboro": "NC",
    "Harrisburg": "PA",
    "Hartford": "CT",
    "Hialeah": "FL",
    "Hollywood": "FL",
    "Huntsville": "AL",
    "Independence": "MO",
    "Indianapolis": "IN",
    "Iowa City": "IA",
    "Irving": "TX",
    "Jackson": "MS",
    "Jacksonville": "FL",
    "Jefferson City": "MO",
    "Juneau": "AK",
    "Kansas City": "KS",
    "Knoxville": "TN",
    "Lancaster": "PA",
    "Lansing": "MI",
    "Laredo": "TX",
    "Lexington": "KY",
    "Little Rock": "AR",
    "Long Beach": "CA",
    "Louisville": "KY",
    "Lubbock": "TX",
    "Macon": "GA",
    "Manchester": "NH",
    "Memphis": "TN",
    "Mesa": "AZ",
    "Miami": "FL",
    "Milwaukee": "WI",
    "Mobile": "AL",
    "Modesto": "CA",
    "Montgomery": "AL",
    "Montpelier": "VT",
    "Naperville": "IL",
    "Nashville": "TN",
    "New Haven": "CT",
    "New Orleans": "LA",
    "Newport News": "VA",
    "Oakland": "CA",
    "Oceanside": "CA",
    "Oklahoma City": "OK",
    "Omaha": "NE",
    "Orlando": "FL",
    "Oxnard": "CA",
    "Palm Bay": "FL",
    "Pasadena": "CA",
    "Pasadena": "TX",
    "Peoria": "IL",
    "Phoenix": "AZ",
    "Pittsburgh": "PA",
    "Plano": "TX",
    "Portland": "ME",
    "Providence": "RI",
    "Provo": "UT",
    "Raleigh": "NC",
    "Reno": "NV",
    "Richmond": "VA",
    "Riverside": "CA",
    "Rochester": "NY",
    "Rockford": "IL",
    "Sacramento": "CA",
    "Salem": "OR",
    "Salinas": "CA",
    "Salt Lake City": "UT",
    "San Antonio": "TX",
    "San Bernardino": "CA",
    "San Diego": "CA",
    "San Francisco": "CA",
    "San Jose": "CA",
    "Santa Ana": "CA",
    "Santa Barbara": "CA",
    "Santa Clara": "CA",
    "Santa Fe": "NM",
    "Santa Rosa": "CA",
    "Savannah": "GA",
    "Scottsdale": "AZ",
    "Seattle": "WA",
    "Shreveport": "LA",
    "Sioux Falls": "SD",
    "South Bend": "IN",
    "Spokane": "WA",
    "Springfield": "IL",
    "Springfield": "MA",
    "Springfield": "MO",
    "St. Louis": "MO",
    "St. Paul": "MN",
    "St. Petersburg": "FL",
    "Stamford": "CT",
    "Sterling Heights": "MI",
    "Stockton": "CA",
    "Sunnyvale": "CA",
    "Syracuse": "NY",
    "Tacoma": "WA",
    "Tallahassee": "FL",
    "Tampa": "FL",
    "Tempe": "AZ",
    "Thousand Oaks": "CA",
    "Toledo": "OH",
    "Topeka": "KS",
    "Tucson": "AZ",
    "Tulsa": "OK",
    "Virginia Beach": "VA",
    "Waco": "TX",
    "Warren": "MI",
    "Waterbury": "CT",
    "West Valley City": "UT",
    "Wichita": "KS",
    "Wilmington": "DE",
    "Winston-Salem": "NC",
    "Worcester": "MA",
    "Yonkers": "NY"
  };

  // If the name already includes a comma, it might already be in City, State format
  if (cityName && cityName.includes(',')) {
    const parts = cityName.split(',').map(part => part.trim());
    if (parts.length === 2 && parts[1].length === 2) {
      // Looks like "City, ST" format already
      return cityName;
    }
  }

  // Special case for Washington DC
  if (cityName === "Washington" || cityName === "Washington D.C." || cityName === "Washington DC") {
    return "Washington, DC";
  }

  // Check if the city is in our US cities map
  if (usCityToStateMap[cityName]) {
    return `${cityName}, ${usCityToStateMap[cityName]}`;
  }

  // Handle cities that already have USA in them
  if (cityName && cityName.endsWith("USA")) {
    const cityWithoutUSA = cityName.replace(", USA", "");
    if (usCityToStateMap[cityWithoutUSA]) {
      return `${cityWithoutUSA}, ${usCityToStateMap[cityWithoutUSA]}`;
    }
  }

  // Look through our city/state list to see if any entries start with this city name
  if (cityName) {
    const possibleMatch = CITY_STATE_LIST.find(entry => 
      entry.toLowerCase().startsWith(cityName.toLowerCase() + ',')
    );
    
    if (possibleMatch) {
      return possibleMatch;
    }
  }

  // Return the original name if not a US city or can't find a match
  return cityName;
};