/**
 * Utility functions for getting emoji weather icons
 */

/**
 * Get weather emoji based on condition and icon code
 * @param {string} condition - Weather condition (e.g., "Clear", "Rain")
 * @param {string} icon - OpenWeatherMap icon code (e.g., "01d", "10n")
 * @returns {string} Weather emoji
 */
export const getWeatherIcon = (condition, icon = '') => {
  const conditionLower = (condition || '').toLowerCase();
  const isDay = !icon.endsWith('n'); // 'n' suffix indicates night icons
  
  // Clear sky
  if (conditionLower.includes('clear') || icon.includes('01')) {
    return isDay ? '☀️' : '🌙';
  }
  
  // Partly cloudy
  if ((conditionLower.includes('cloud') && (conditionLower.includes('few') || conditionLower.includes('part'))) || 
      icon.includes('02') || icon.includes('03')) {
    return isDay ? '⛅' : '🌙';
  }
  
  // Cloudy
  if (conditionLower.includes('cloud') || conditionLower.includes('overcast') || icon.includes('04')) {
    return '☁️';
  }
  
  // Mist, fog, haze
  if (conditionLower.includes('mist') || conditionLower.includes('fog') || conditionLower.includes('haze') || 
      icon.includes('50')) {
    return '🌫️';
  }
  
  // Drizzle or light rain
  if (conditionLower.includes('drizzle') || 
      (conditionLower.includes('rain') && conditionLower.includes('light')) || 
      icon.includes('09') || icon.includes('10')) {
    return '🌦️';
  }
  
  // Heavy rain or shower
  if ((conditionLower.includes('rain') && (conditionLower.includes('heavy') || conditionLower.includes('shower'))) || 
      icon.includes('09')) {
    return '🌧️';
  }
  
  // Thunderstorm
  if (conditionLower.includes('thunder') || conditionLower.includes('storm') || 
      conditionLower.includes('lightning') || icon.includes('11')) {
    return '⛈️';
  }
  
  // Snow
  if (conditionLower.includes('snow') || icon.includes('13')) {
    return '❄️';
  }
  
  // Sleet or mix
  if (conditionLower.includes('sleet') || conditionLower.includes('mix')) {
    return '🌨️';
  }
  
  // Dust, sand, ash
  if (conditionLower.includes('dust') || conditionLower.includes('sand') || conditionLower.includes('ash')) {
    return '💨';
  }
  
  // Default weather icon
  return '☁️';
};

/**
 * Get moon phase emoji based on phase percentage
 * @param {number} phasePercentage - Moon phase as percentage (0-100)
 * @returns {string} Moon phase emoji
 */
export const getMoonPhaseIcon = (phasePercentage) => {
  // Normalize the percentage to 0-100 range if needed
  const normalizedPercentage = (phasePercentage % 100 + 100) % 100;
  
  // Map the phase percentage to appropriate moon emoji
  if (normalizedPercentage < 3.5 || normalizedPercentage > 96.5) {
    return '🌑'; // New Moon
  } else if (normalizedPercentage < 10.5) {
    return '🌒'; // Waxing Crescent
  } else if (normalizedPercentage < 21.5) {
    return '🌓'; // First Quarter
  } else if (normalizedPercentage < 46.5) {
    return '🌔'; // Waxing Gibbous
  } else if (normalizedPercentage < 53.5) {
    return '🌕'; // Full Moon
  } else if (normalizedPercentage < 78.5) {
    return '🌖'; // Waning Gibbous
  } else if (normalizedPercentage < 89.5) {
    return '🌗'; // Last Quarter
  } else {
    return '🌘'; // Waning Crescent
  }
};

/**
 * Calculate the current moon phase percentage
 * Uses a simple approximation formula
 * @param {Date} date - Date to calculate the moon phase for
 * @returns {number} Moon phase percentage (0-100)
 */
export const calculateMoonPhase = (date = new Date()) => {
  // Reference date (known new moon): January 6, 2000
  const referenceNewMoon = new Date(2000, 0, 6).getTime();
  const currentDate = date.getTime();
  
  // The moon's orbital period is approximately 29.53 days
  const lunarCycle = 29.53 * 24 * 60 * 60 * 1000;
  
  // Calculate how many lunar cycles have passed
  const daysSinceReference = (currentDate - referenceNewMoon) / lunarCycle;
  
  // Get the fractional part of the cycle
  const fractionalPart = daysSinceReference % 1;
  
  // Convert to percentage
  return fractionalPart * 100;
};

/**
 * Get the appropriate sun/moon icon class based on time of day
 * @param {boolean} isSunrise - Whether to get sunrise or sunset icon
 * @returns {string} Font Awesome icon class
 */
export const getSunMoonIcon = (isSunrise = true) => {
  return isSunrise ? 'fa-sunrise' : 'fa-sunset'; // or substitute with appropriate Font Awesome icons
};

/**
 * Get icon for daylight or moonlight
 * @param {boolean} isDaylight - Whether to get daylight or moonlight icon
 * @returns {string} Font Awesome icon class
 */
export const getDaylightIcon = (isDaylight = true) => {
  return isDaylight ? 'fa-sun' : 'fa-moon';
};

/**
 * Get wind icon based on wind speed
 * @param {number} windSpeed - Wind speed in mph
 * @returns {string} Font Awesome icon class with appropriate intensity
 */
export const getWindIcon = (windSpeed) => {
  if (windSpeed < 5) {
    return 'fa-wind'; // Light breeze
  } else if (windSpeed < 15) {
    return 'fa-wind'; // Wind blowing
  } else if (windSpeed < 25) {
    return 'fa-wind'; // Stronger wind
  } else {
    return 'fa-tornado'; // Strong wind/tornado
  }
};

/**
 * Get humidity icon based on humidity percentage
 * @param {number} humidity - Humidity percentage
 * @returns {string} Font Awesome icon class
 */
export const getHumidityIcon = (humidity) => {
  if (humidity < 30) {
    return 'fa-tint-slash'; // Dry
  } else if (humidity < 60) {
    return 'fa-tint'; // Medium humidity
  } else {
    return 'fa-tint'; // High humidity
  }
};