import React, { useState, useEffect } from 'react';
import SearchForm from './components/SearchForm';
import WeatherComparison from './components/WeatherComparison';
import { 
  getWeatherByZip,
  getWeatherByCity,
  findSimilarWeatherLocations,
  findOppositeWeatherLocations,
  getCurrentLocationWeather
} from './services/weatherService';
import './styles/App.css';
import './styles/leaflet-fix.css';

function App() {
  const [weatherData, setWeatherData] = useState(null);
  const [similarLocations, setSimilarLocations] = useState([]);
  const [oppositeLocations, setOppositeLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showOppositeWeather, setShowOppositeWeather] = useState(false); // Toggle for showing opposite weather
  const [showSearchForm, setShowSearchForm] = useState(true); // Toggle for showing/hiding search form

  const handleSearch = async (searchTerm, searchType) => {
    setLoading(true);
    setError(null);
    
    try {
      let data;
      
      if (searchType === 'zip') {
        data = await getWeatherByZip(searchTerm);
      } else if (searchType === 'city') {
        data = await getWeatherByCity(searchTerm);
      } else {
        setError(new Error('Invalid search type'));
        setLoading(false);
        return;
      }
      
      setWeatherData(data);
      
      // Find locations with similar and opposite weather concurrently
      const [similarData, oppositeData] = await Promise.all([
        findSimilarWeatherLocations(data.main.temp, data.weather[0].main),
        findOppositeWeatherLocations(data.main.temp, data.weather[0].main)
      ]);
      
      setSimilarLocations(similarData);
      setOppositeLocations(oppositeData);
    } catch (err) {
      setError(err);
      console.error('Search error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleUseMyLocation = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const data = await getCurrentLocationWeather();
      setWeatherData(data);
      
      // Find locations with similar and opposite weather concurrently
      const [similarData, oppositeData] = await Promise.all([
        findSimilarWeatherLocations(data.main.temp, data.weather[0].main),
        findOppositeWeatherLocations(data.main.temp, data.weather[0].main)
      ]);
      
      setSimilarLocations(similarData);
      setOppositeLocations(oppositeData);
    } catch (err) {
      setError(err);
      console.error('Geolocation error:', err);
    } finally {
      setLoading(false);
    }
  };

  // Function to get time of day greeting with a fun random adjective
  const getGreeting = () => {
    const hour = new Date().getHours();
    
    // Time-based greeting
    let greeting = '';
    if (hour < 12) greeting = 'Good morning';
    else if (hour < 18) greeting = 'Good afternoon';
    else greeting = 'Good evening';
    
    // Fun weather enthusiast words
    const funAdjectives = [
      'weather wanderer',
      'climate explorer',
      'weather wizard',
      'meteorology fan',
      'forecast follower',
      'atmosphere aficionado',
      'weather watcher',
      'climate curious',
      'forecast finder',
      'weather enthusiast',
      'sky gazer',
      'weather voyager'
    ];
    
    // Get a random adjective based on the day to keep it consistent for the session
    const dayOfYear = Math.floor((new Date() - new Date(new Date().getFullYear(), 0, 0)) / 86400000);
    const randomIndex = dayOfYear % funAdjectives.length;
    
    return `${greeting}, ${funAdjectives[randomIndex]}`;
  };

  // Auto-select the first location based on weather type toggle
  React.useEffect(() => {
    if (weatherData) {
      if (showOppositeWeather && oppositeLocations.length > 0) {
        setSelectedLocation(oppositeLocations[0]);
      } else if (similarLocations.length > 0) {
        setSelectedLocation(similarLocations[0]);
      }
      
      // Hide search form when we have weather data and a location selected
      if (weatherData && (similarLocations.length > 0 || oppositeLocations.length > 0)) {
        setShowSearchForm(false);
      }
    }
  }, [weatherData, similarLocations, oppositeLocations, showOppositeWeather]);
  
  // Toggle between common and opposite weather
  const handleToggleWeatherType = () => {
    setShowOppositeWeather(!showOppositeWeather);
  };

  return (
    <div className="app app-with-sidebar">
      <header className="app-header app-sidebar">
        <div className="app-logo-container">
          <img 
            src="/images/logo.svg" 
            alt="Common Weather Logo" 
            className="app-logo" 
            onClick={() => {
              // Reset app state to return to homepage
              setWeatherData(null);
              setSimilarLocations([]);
              setOppositeLocations([]);
              setSelectedLocation(null);
              setShowSearchForm(true);
            }}
            style={{ cursor: 'pointer' }}
            title="Return to homepage"
          />
        </div>
        <p className="app-tagline">Find common ground in the sky</p>
        
        {/* Additional sidebar content - can add navigation links here if needed */}
        <div className="sidebar-nav">
          {showSearchForm ? null : (
            <button className="sidebar-nav-button" onClick={() => setShowSearchForm(true)}>
              <i className="fas fa-search"></i> Search
            </button>
          )}
        </div>
      </header>
      
      <div className="content-wrapper">
        <main className="app-main app-main-with-sidebar">
        {showSearchForm ? (
          <section className="search-section">
            <h2>{getGreeting()}</h2>
            <p>Enter your location to find similar climates worldwide</p>
            <SearchForm 
              onSearch={handleSearch} 
              isLoading={loading} 
              onUseMyLocation={handleUseMyLocation}
            />
          </section>
        ) : (
          <section className="change-location-section">
            <button className="change-location-button" onClick={() => setShowSearchForm(true)}>
              <i className="fas fa-search"></i> Change Location
            </button>
          </section>
        )}
        
        {weatherData && selectedLocation && (
          <section className="results-section">
            <div className="comparison-container">
              <div className="weather-type-toggle">
                <button 
                  className={`toggle-button ${!showOppositeWeather ? 'active' : ''}`}
                  onClick={() => setShowOppositeWeather(false)}
                >
                  Common Weather
                </button>
                <button 
                  className={`toggle-button ${showOppositeWeather ? 'active' : ''}`}
                  onClick={() => setShowOppositeWeather(true)}
                >
                  Opposite Weather
                </button>
              </div>
              
              <WeatherComparison 
                yourLocation={weatherData} 
                otherLocation={selectedLocation}
                showOppositeWeather={showOppositeWeather}
              />
              
              {/* Miniature location selector below comparison */}
              <div className="location-selector">
                <h3>{showOppositeWeather ? 'Other Opposite Weather Locations' : 'Other Common Weather Locations'}</h3>
                <div className="location-selector-grid">
                  {(showOppositeWeather ? oppositeLocations : similarLocations).map((location, index) => (
                    <div 
                      key={`${location.name}-${index}`}
                      className={`location-selector-card ${selectedLocation === location ? 'selected' : ''}`}
                      onClick={() => setSelectedLocation(location)}
                    >
                      <div className="mini-card-content">
                        <div className="mini-location-name">{location.formattedLocation || location.name}</div>
                        <div className="mini-location-temp">{Math.round(location.temp)}°F</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      
        <footer className="app-footer app-footer-with-sidebar">
          <p>© 2025 Common Weather • Made with <i className="fas fa-cloud"></i> + code in Dallas, TX</p>
        </footer>
      </div>
    </div>
  );
}

export default App;