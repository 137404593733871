import React, { useState, useEffect } from 'react';
import { generateWeatherComparison } from '../utils/similarityUtils';
import LocationMap from './LocationMap';
import ForecastSnippet from './ForecastSnippet';
import { getForecastByCoords, getForecastByCity, formatUSCityName } from '../services/weatherService';
import { 
  getWeatherIcon as getWeatherEmoji, 
  getSunMoonIcon,
  getDaylightIcon,
  getWindIcon,
  getHumidityIcon,
  calculateMoonPhase,
  getMoonPhaseIcon
} from '../utils/weatherEmojis';

const WeatherComparison = ({ yourLocation, otherLocation, showOppositeWeather }) => {
  // Initialize state hooks before any conditional returns
  const [yourForecast, setYourForecast] = useState(null);
  const [otherForecast, setOtherForecast] = useState(null);
  const [forecastLoading, setForecastLoading] = useState(false);
  const [forecastError, setForecastError] = useState(null);
  
  // Fetch forecast data for both locations
  useEffect(() => {
    // Skip fetch if we don't have both locations
    if (!yourLocation || !otherLocation) return;
    
    const fetchForecasts = async () => {
      setForecastLoading(true);
      setForecastError(null);
      
      try {
        // Get forecast for your location
        if (yourLocation && yourLocation.coord) {
          const yourForecastData = await getForecastByCoords(
            yourLocation.coord.lat,
            yourLocation.coord.lon
          );
          setYourForecast(yourForecastData);
        }
        
        // Get forecast for other location
        if (otherLocation) {
          let otherForecastData;
          if (otherLocation.name) {
            otherForecastData = await getForecastByCity(otherLocation.name);
          } else if (otherLocation.lat && otherLocation.lon) {
            otherForecastData = await getForecastByCoords(
              otherLocation.lat,
              otherLocation.lon
            );
          }
          if (otherForecastData) {
            setOtherForecast(otherForecastData);
          }
        }
      } catch (err) {
        console.error('Error fetching forecast data:', err);
        setForecastError(err);
      } finally {
        setForecastLoading(false);
      }
    };
    
    fetchForecasts();
  }, [yourLocation, otherLocation]);

  // Early return after hooks
  if (!yourLocation || !otherLocation) return null;
  
  // Generate comparison with null check for safety
  const comparison = generateWeatherComparison(yourLocation, otherLocation);
  
  // Use the formatted location if available, otherwise format the name
  const yourFormattedName = yourLocation.formattedLocation || formatUSCityName(yourLocation.name);
  const otherFormattedName = otherLocation.formattedLocation || formatUSCityName(otherLocation.name);
  
  // Prepare data for the maps
  const yourLocationData = {
    name: yourFormattedName,
    lat: yourLocation.coord?.lat || 0,
    lon: yourLocation.coord?.lon || 0
  };
  
  const otherLocationData = {
    name: otherFormattedName,
    lat: otherLocation.lat || otherLocation.coord?.lat || 0,
    lon: otherLocation.lon || otherLocation.coord?.lon || 0
  };
  
  // Calculate distance between locations
  const calculateDistance = () => {
    // Check if we have valid coordinates for both locations
    if (!yourLocationData.lat || !yourLocationData.lon || 
        !otherLocationData.lat || !otherLocationData.lon) {
      return 1000; // Return a default distance if coordinates are missing
    }
    
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(otherLocationData.lat - yourLocationData.lat);
    const dLon = deg2rad(otherLocationData.lon - yourLocationData.lon);
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(yourLocationData.lat)) * Math.cos(deg2rad(otherLocationData.lat)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const distance = R * c; // Distance in km
    
    return Math.round(distance);
  };
  
  const deg2rad = (deg) => {
    return deg * (Math.PI/180);
  };
  
  // Interesting facts about the locations
  const getTimezoneDifference = () => {
    // This is a simplified version - in a real app, you'd get timezone data from an API
    const timezoneOffset = (otherLocation.timezone || 0) - (yourLocation.timezone || 0);
    const hours = Math.abs(Math.round(timezoneOffset / 3600));
    
    if (hours === 0) return "Both locations are in the same timezone";
    return `${otherLocation.name} is ${hours} hour${hours !== 1 ? 's' : ''} ${timezoneOffset > 0 ? 'ahead of' : 'behind'} ${yourLocation.name}`;
  };
  
  // Determine the weather background class with refined effects
  const getBackgroundClass = (location) => {
    // If it's the OpenWeatherMap API data
    if (location.weather && location.weather[0].icon) {
      const iconCode = location.weather[0].icon;
      const weatherId = location.weather[0].id;
      let baseClass = '';
      
      // First determine the base class
      switch (iconCode) {
        // Sunny
        case '01d':
        case '01n':
          baseClass = 'bg-sunny';
          break;
        
        // Clouds + Sun
        case '02d':
        case '02n':
          baseClass = 'bg-partly-cloudy';
          break;
        
        // Light/Medium Rain (drizzle, light rain)
        case '10d':
        case '10n':
          baseClass = 'bg-light-rain';
          break;
        
        // Cloudy
        case '03d':
        case '03n':
        case '04d':
        case '04n':
          baseClass = 'bg-cloudy';
          break;
          
        // Heavy Rain
        case '09d':
        case '09n':
          baseClass = 'bg-rain';
          break;
          
        // Thunderstorm
        case '11d':
        case '11n':
          baseClass = 'bg-cloudy thunder';
          break;
        
        // Snow
        case '13d':
        case '13n':
          baseClass = 'bg-snow';
          break;
          
        // Mist/Fog
        case '50d':
        case '50n':
          baseClass = 'bg-mist';
          break;
        
        default:
          baseClass = 'bg-default';
      }
      
      // Only add rain class to cloudy when it's actually raining
      if (weatherId) {
        // Drizzle (300-321)
        if (weatherId >= 300 && weatherId < 400) {
          return 'bg-light-rain';
        }
        
        // Rain (500-531)
        if (weatherId >= 500 && weatherId < 600) {
          return weatherId >= 520 ? 'bg-rain' : 'bg-light-rain';
        }
        
        // Heavy thunderstorm (Severe)
        if (weatherId >= 200 && weatherId < 300 && weatherId >= 210) {
          return 'bg-cloudy thunder';
        }
      }
      
      return baseClass;
    } 
    
    // For our similar location data
    let baseClass = 'bg-default';
    
    // If we have a condition string, use it to determine class
    if (location.condition) {
      const condition = location.condition.toLowerCase();
      if (condition.includes('clear') || condition.includes('sunny')) {
        baseClass = 'bg-sunny';
      } else if (condition.includes('cloud') && (condition.includes('part') || condition.includes('few'))) {
        baseClass = 'bg-partly-cloudy';
      } else if (condition.includes('cloud') || condition.includes('overcast')) {
        baseClass = 'bg-cloudy';
      } else if (condition.includes('snow')) {
        baseClass = 'bg-snow';
      } else if (condition.includes('mist') || condition.includes('fog') || condition.includes('haze')) {
        baseClass = 'bg-mist';
      }
      
      // Add rain effect - be more specific
      if (condition.includes('rain')) {
        if (condition.includes('light') || condition.includes('drizzle')) {
          return 'bg-light-rain';
        } else if (condition.includes('heavy') || condition.includes('shower')) {
          return 'bg-rain';
        } else {
          return 'bg-light-rain';
        }
      }
      
      // Add thunder effect
      if (condition.includes('thunder') || condition.includes('lightning')) {
        return 'bg-cloudy thunder';
      }
    }
    
    return baseClass;
  };

  // Get color based on similarity percentage
  const getSimilarityColor = () => {
    const score = comparison.similarity;
    if (score >= 90) return '#4CAF50'; // Green
    if (score >= 80) return '#8BC34A'; // Light Green
    if (score >= 70) return '#CDDC39'; // Lime
    if (score >= 60) return '#FFC107'; // Amber
    if (score >= 50) return '#FF9800'; // Orange
    return '#F44336'; // Red
  };
  
  const similarityColor = getSimilarityColor();
  
  // Simulate population data based on location name
  const getSimulatedPopulation = (locationName) => {
    if (!locationName) return 100000;
    
    // Use a hash-like function to generate a consistent but pseudo-random number
    // based on the location name
    let hash = 0;
    for (let i = 0; i < locationName.length; i++) {
      hash = ((hash << 5) - hash) + locationName.charCodeAt(i);
      hash = hash & hash; // Convert to 32bit integer
    }
    
    // Base population on city "type"
    let basePop = 100000; // Default medium city
    
    // Extract the name part before any comma (city name)
    const cityName = locationName.split(',')[0].trim().toLowerCase();
    
    // Check for major world cities and metropolitan areas
    const majorCities = ['new york', 'tokyo', 'london', 'paris', 'shanghai', 'beijing',
      'delhi', 'mumbai', 'los angeles', 'chicago', 'houston', 'mexico city', 
      'sao paulo', 'seoul', 'jakarta', 'cairo'];
    
    const largeCities = ['philadelphia', 'phoenix', 'dallas', 'san diego', 'toronto',
      'miami', 'singapore', 'barcelona', 'sydney', 'berlin', 'madrid', 'rome',
      'hong kong', 'bangkok', 'boston', 'atlanta', 'seattle', 'denver'];
    
    if (majorCities.some(city => cityName.includes(city))) {
      basePop = 5000000 + (Math.abs(hash) % 10000000); // 5-15 million
    } else if (largeCities.some(city => cityName.includes(city))) {
      basePop = 1000000 + (Math.abs(hash) % 3000000); // 1-4 million
    } else {
      // For other cities, randomize between small city to medium city
      basePop = 50000 + (Math.abs(hash) % 950000); // 50k-1 million
    }
    
    return Math.round(basePop);
  };
  
  // Format population with commas
  const formatPopulation = (population) => {
    return new Intl.NumberFormat().format(population);
  };
  
  // Simulate elevation data based on coordinates
  const getSimulatedElevation = (lat, lon) => {
    if (!lat || !lon) return 100;
    
    // This is a very simplified simulation of elevation
    // In a real app, this would come from an elevation API
    
    // Use coordinates to seed a predictable value
    const seed = Math.abs(lat * 100 + lon * 100);
    
    // Some coastal areas (near zero elevation)
    if (Math.abs(lon) > 160 || // Pacific islands
        (Math.abs(lat) < 23 && Math.abs(lon - 80) < 15) || // Florida, Caribbean
        (lat > 30 && lat < 60 && Math.abs(lon) > 110)) { // West coast
      return Math.round(seed % 100); // 0-100m elevation
    }
    
    // Mountain ranges
    if ((lat > 35 && lat < 50 && lon > -125 && lon < -105) || // Rocky Mountains
        (lat > 36 && lat < 42 && lon > -80 && lon < -70) || // Appalachians
        (lat > 43 && lat < 47 && lon > 5 && lon < 10) || // Alps
        (lat > 27 && lat < 40 && lon > 80 && lon < 95)) { // Himalayas
      return 1000 + Math.round((seed % 3000)); // 1000-4000m elevation
    }
    
    // For most places, modest elevation
    return 100 + Math.round((seed % 900)); // 100-1000m elevation
  };
  
  // Get weather emoji based on condition string
  const getLocalWeatherEmoji = (condition) => {
    if (!condition) return '☁️';
    
    const conditionLower = condition.toLowerCase();
    if (conditionLower.includes('clear') || conditionLower.includes('sun')) {
      return '☀️';
    } else if (conditionLower.includes('cloud') && (conditionLower.includes('part') || conditionLower.includes('few'))) {
      return '⛅';
    } else if (conditionLower.includes('cloud') || conditionLower.includes('overcast')) {
      return '☁️';
    } else if (conditionLower.includes('rain') || conditionLower.includes('drizzle') || conditionLower.includes('shower')) {
      return conditionLower.includes('light') ? '🌦️' : '🌧️';
    } else if (conditionLower.includes('storm') || conditionLower.includes('thunder')) {
      return '⛈️';
    } else if (conditionLower.includes('snow')) {
      return '❄️';
    } else if (conditionLower.includes('mist') || conditionLower.includes('fog') || conditionLower.includes('haze')) {
      return '🌫️';
    }
    return '☁️';
  };
  
  // Calculate and format daylight information
  const getDaylightInfo = (weatherData) => {
    // Check if we have sunset and sunrise data
    if (!weatherData.sys || !weatherData.sys.sunrise || !weatherData.sys.sunset) {
      return { 
        sunrise: 'N/A', 
        sunset: 'N/A', 
        daylightDuration: 'N/A',
        nightDuration: 'N/A'
      };
    }
    
    // Extract sunrise, sunset, and timezone from the API response
    const { sunrise, sunset } = weatherData.sys;
    const { timezone } = weatherData;
    
    // Convert Unix timestamps to local time (milliseconds)
    const sunriseDate = new Date((sunrise + timezone) * 1000);
    const sunsetDate = new Date((sunset + timezone) * 1000);
    
    // Format sunrise and sunset times (e.g., "6:45 AM")
    const formatTime = (date) => {
      return date.toLocaleTimeString('en-US', { 
        hour: 'numeric', 
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC' // Using UTC because we already adjusted for timezone
      });
    };
    
    const sunriseTime = formatTime(sunriseDate);
    const sunsetTime = formatTime(sunsetDate);
    
    // Calculate total daylight duration in seconds
    const daylightSeconds = sunset - sunrise;
    
    // Calculate total night duration (24 hours - daylight)
    const nightSeconds = 86400 - daylightSeconds; // 86400 seconds in 24 hours
    
    // Convert to hours and minutes
    const daylightHours = Math.floor(daylightSeconds / 3600);
    const daylightMinutes = Math.floor((daylightSeconds % 3600) / 60);
    
    const nightHours = Math.floor(nightSeconds / 3600);
    const nightMinutes = Math.floor((nightSeconds % 3600) / 60);
    
    // Format daylight and night durations (e.g., "12h 45m")
    const daylightDuration = `${daylightHours}h ${daylightMinutes}m`;
    const nightDuration = `${nightHours}h ${nightMinutes}m`;
    
    return {
      sunrise: sunriseTime,
      sunset: sunsetTime,
      daylightDuration,
      nightDuration
    };
  };

  // Function to generate interesting weather and geographical facts between locations
  const generateWeatherFact = (location1, location2) => {
    // Get basic info for both locations
    const name1 = yourFormattedName;
    const name2 = otherFormattedName;
    const temp1 = Math.round(location1.main?.temp || location1.temp);
    const temp2 = Math.round(location2.main?.temp || location2.temp);
    const lat1 = location1.coord?.lat || location1.lat;
    const lat2 = location2.coord?.lat || location2.lat;
    
    // Calculate hemisphere for each location
    const hemisphere1 = lat1 >= 0 ? "Northern" : "Southern";
    const hemisphere2 = lat2 >= 0 ? "Northern" : "Southern";
    
    // Get distance between locations
    const distance = calculateDistance();
    
    // Get weather conditions
    const weather1 = location1.weather?.[0]?.main || location1.condition;
    const weather2 = location2.weather?.[0]?.main || location2.condition;
    
    // Interesting fact types
    const facts = [
      // Hemisphere facts
      hemisphere1 !== hemisphere2 
        ? `While ${name1} is experiencing ${temp1}°F in the ${hemisphere1} Hemisphere, ${name2} is at ${temp2}°F in the ${hemisphere2} Hemisphere - that means they're experiencing opposite seasons!`
        : `Both ${name1} and ${name2} are in the ${hemisphere1} Hemisphere, sharing the same seasonal patterns despite being ${distance} km apart.`,
      
      // Same weather, different continents
      (weather1 === weather2 && distance > 5000) 
        ? `It's ${weather1.toLowerCase()} in both ${name1} and ${name2} today, despite being roughly ${Math.round(distance/1000)}k km apart — sometimes distant places share surprising weather connections!`
        : `${name1} (${temp1}°F) and ${name2} (${temp2}°F) are experiencing a temperature difference of ${Math.abs(temp1-temp2)}°F despite both seeing ${weather1.toLowerCase()} conditions today.`,
      
      // Cultural weather facts based on location names
      `If you dislike ${weather1.toLowerCase()} in ${name1}, you could teleport to ${name2} where it's ${weather2.toLowerCase()} today - a complete change of scenery and weather in just ${Math.round(distance/800)} hours of flight time.`,
      
      // Temperature extremes
      Math.abs(temp1 - temp2) > 5
        ? `While ${name1} residents are experiencing ${temp1}°F, the people of ${name2} are dealing with temperatures ${temp1 > temp2 ? "a whole " + (temp1-temp2) + "°F cooler" : "a significant " + (temp2-temp1) + "°F warmer"}!`
        : `Despite being separated by ${Math.round(distance * 0.621371)} miles, ${name1} and ${name2} are experiencing nearly identical temperatures today (within 5°F) - a weather coincidence!`,
      
      // Seasonal peculiarities
      `If you're in ${name1} today, you'd need to travel ${Math.round(distance * 0.621371)} miles to ${name2} to experience similar weather conditions - but local weather patterns mean this similarity could vanish tomorrow!`,
      
      // Time difference facts
      Math.abs((location1.timezone - location2.timezone)/3600) > 5 
        ? `Weather feels different in ${name1} vs ${name2} partly because they're ${Math.abs(Math.round((location1.timezone - location2.timezone)/3600))} time zones apart - one place could be experiencing morning dew while the other is in afternoon heat.`
        : `Despite being thousands of miles apart, ${name1} and ${name2} are both experiencing ${weather1.toLowerCase()} conditions at roughly the same time of day - synchronized weather across continents!`
    ];
    
    // Randomly select a fact
    return facts[Math.floor(Math.random() * facts.length)];
  };
  
  return (
    <div className="weather-comparison">
      <h1 className="comparison-title">Current Weather Comparison</h1>
      
      <div className="current-conditions-comparison">
        <div className={`condition-card ${getBackgroundClass(yourLocation)}`}>
          <h3>{yourFormattedName}</h3>
          <div className="condition-details">
            <div className="condition-temperature">
              {Math.round(yourLocation.main.temp)}°F
            </div>
            <div className="condition-icon">
              {yourLocation.weather && 
                getWeatherEmoji(yourLocation.weather[0].main, yourLocation.weather[0].icon)}
            </div>
            <div className="condition-description">
              {yourLocation.weather ? yourLocation.weather[0].description : 'Current conditions'}
            </div>
          </div>
          <div className="condition-datetime">
            <div className="condition-time">
              <i className="fas fa-clock"></i> {
                (() => {
                  // Get current UTC time
                  const now = new Date();
                  const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);
                  // Add the location's timezone offset
                  const locationTime = new Date(utcTime + (yourLocation.timezone * 1000));
                  return locationTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
                })()
              }
            </div>
            <div className="condition-date">
              <i className="fas fa-calendar-day"></i> {
                (() => {
                  // Get current UTC time
                  const now = new Date();
                  const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);
                  // Add the location's timezone offset
                  const locationTime = new Date(utcTime + (yourLocation.timezone * 1000));
                  return locationTime.toLocaleDateString([], {weekday: 'short', month: 'short', day: 'numeric'});
                })()
              }
            </div>
          </div>
        </div>
        
        <div className="similarity-gauge">
          <div className="similarity-circle" style={{ background: `conic-gradient(${similarityColor} ${comparison.similarity * 3.6}deg, #e0e0e0 ${comparison.similarity * 3.6}deg)` }}>
            <div className="similarity-inner">
              <div className="similarity-percent">{comparison.similarity}%</div>
            </div>
          </div>
          <div className="similarity-label">Similarity Score</div>
        </div>
        
        <div className={`condition-card ${getBackgroundClass(otherLocation)}`}>
          <h3>{otherFormattedName}</h3>
          <div className="condition-details">
            <div className="condition-temperature">
              {Math.round(otherLocation.temp)}°F
            </div>
            <div className="condition-icon">
              {getLocalWeatherEmoji(otherLocation.condition)}
            </div>
            <div className="condition-description">
              {otherLocation.condition || 'Current conditions'}
            </div>
          </div>
          <div className="condition-datetime">
            <div className="condition-time">
              <i className="fas fa-clock"></i> {
                (() => {
                  // Get current UTC time
                  const now = new Date();
                  const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);
                  // Add the location's timezone offset
                  const locationTime = new Date(utcTime + (otherLocation.timezone * 1000));
                  return locationTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
                })()
              }
            </div>
            <div className="condition-date">
              <i className="fas fa-calendar-day"></i> {
                (() => {
                  // Get current UTC time
                  const now = new Date();
                  const utcTime = now.getTime() + (now.getTimezoneOffset() * 60000);
                  // Add the location's timezone offset
                  const locationTime = new Date(utcTime + (otherLocation.timezone * 1000));
                  return locationTime.toLocaleDateString([], {weekday: 'short', month: 'short', day: 'numeric'});
                })()
              }
            </div>
          </div>
        </div>
      </div>
      
      {/* Weather Details Comparison - Moved here from below */}
      <div className="comparison-details-container">
        <h3 className="comparison-details-title">Weather Details Comparison</h3>
        <div className="comparison-details">
          <div className="comparison-item">
            <div className="info-icon"><i className="fas fa-thermometer-half"></i></div>
            <div className="item-label">Temperature</div>
            <div className="item-value">{comparison.temperature.message}</div>
          </div>
          
          <div className="comparison-item">
            <div className="info-icon"><i className="fas fa-tint"></i></div>
            <div className="item-label">Humidity</div>
            <div className="item-value">{comparison.humidity.message}</div>
          </div>
          
          <div className="comparison-item">
            <div className="info-icon"><i className="fas fa-wind"></i></div>
            <div className="item-label">Wind</div>
            <div className="item-value">{comparison.wind.message}</div>
          </div>
          
          <div className="comparison-item">
            <div className="info-icon"><i className="fas fa-cloud-sun-rain"></i></div>
            <div className="item-label">Weather Condition</div>
            <div className="item-value">{comparison.weatherType.message}</div>
          </div>
        </div>
      </div>
      
      <div className="location-details-section">
        <div className="location-detail-card">
          <h3>{yourFormattedName}</h3>
          <div className="location-summary">
            <div className="current-condition-detail">
              <span className="main-weather-emoji">
                {yourLocation.weather ? 
                  getWeatherEmoji(yourLocation.weather[0].main, yourLocation.weather[0].icon) : 
                  '☁️'}
              </span>
              <div className="condition-text">
                {yourLocation.weather ? yourLocation.weather[0].description : 'Current conditions'}
              </div>
            </div>
            <div className="current-detail-item">
              <i className="fas fa-thermometer-half"></i>
              <span>{Math.round(yourLocation.main.temp)}°F</span>
            </div>
            <div className="current-detail-item temp-range">
              <i className="fas fa-temperature-high"></i>
              <span className="high">{Math.round(yourLocation.main.temp_max)}°</span>
              <span className="divider">/</span>
              <span className="low">{Math.round(yourLocation.main.temp_min)}°</span>
            </div>
            <div className="current-detail-item">
              <i className={`fas ${getHumidityIcon(yourLocation.main.humidity)}`}></i>
              <span>{yourLocation.main.humidity}% humidity</span>
            </div>
            <div className="current-detail-item">
              <i className={`fas ${getWindIcon(yourLocation.wind.speed)}`}></i>
              <span>{Math.round(yourLocation.wind.speed)} mph</span>
            </div>
            
            {/* Sunrise, Sunset, Daylight & Moonlight */}
            {(() => {
              const { sunrise, sunset, daylightDuration, nightDuration } = getDaylightInfo(yourLocation);
              // Calculate moon phase for today
              const moonPhase = calculateMoonPhase();
              const moonPhaseIconClass = getMoonPhaseIcon(moonPhase);
              
              return (
                <div className="daylight-info">
                  <div className="current-detail-item">
                    <i className={`fas fa-sunrise`}></i>
                    <span><b>Sunrise</b>: {sunrise}</span>
                    <span className="separator">|</span>
                    <span><b>Hours of Daylight</b>: {daylightDuration}</span>
                  </div>
                  <div className="current-detail-item">
                    <i className={`fas fa-sunset`}></i>
                    <span><b>Sunset</b>: {sunset}</span>
                    <span className="separator">|</span>
                    <span><b>Hours of Moonlight</b>: {nightDuration}</span>
                  </div>
                  <div className="current-detail-item moon-phase-item">
                    <span className="moon-emoji">{getMoonPhaseIcon(moonPhase)}</span>
                    <span><b>Current Moon Phase</b></span>
                  </div>
                </div>
              );
            })()}
          </div>
          <div className="map-with-forecast">
            <div className="map-container">
              <LocationMap location={yourLocationData} showWeatherLayer={true} />
            </div>
            <div className="forecast-container">
              <ForecastSnippet 
                forecastData={yourForecast} 
                isLoading={forecastLoading} 
                error={forecastError} 
              />
            </div>
          </div>
        </div>
        
        <div className={`location-detail-card ${showOppositeWeather ? 'opposite-card' : ''}`}>
          <h3 className={showOppositeWeather ? 'opposite-header' : ''}>{otherFormattedName}</h3>
          <div className="location-summary">
            <div className="current-condition-detail">
              <span className="main-weather-emoji">
                {getLocalWeatherEmoji(otherLocation.condition)}
              </span>
              <div className="condition-text">
                {otherLocation.condition || 'Current conditions'}
              </div>
            </div>
            <div className="current-detail-item">
              <i className="fas fa-thermometer-half"></i>
              <span>{Math.round(otherLocation.temp)}°F</span>
            </div>
            <div className="current-detail-item temp-range">
              <i className="fas fa-temperature-high"></i>
              <span className="high">{Math.round(otherLocation.temp + 5)}°</span>
              <span className="divider">/</span>
              <span className="low">{Math.round(otherLocation.temp - 5)}°</span>
            </div>
            <div className="current-detail-item">
              <i className={`fas ${getHumidityIcon(otherLocation.humidity)}`}></i>
              <span>{otherLocation.humidity}% humidity</span>
            </div>
            <div className="current-detail-item">
              <i className={`fas ${getWindIcon(otherLocation.windSpeed)}`}></i>
              <span>{Math.round(otherLocation.windSpeed)} mph</span>
            </div>
            
            {/* Estimated daylight info for other location */}
            <div className="daylight-info">
              {/* For other locations, we'll show estimates if no actual data */}
              <div className="current-detail-item">
                <i className="fas fa-sunrise"></i>
                <span><b>Sunrise</b>: {otherLocation.sys?.sunrise ? 
                  getDaylightInfo(otherLocation).sunrise : 
                  "Est. " + new Date(new Date().setHours(6, Math.floor(Math.random() * 30), 0, 0)).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit', hour12: true})}</span>
                <span className="separator">|</span>
                <span><b>Hours of Daylight</b>: {otherLocation.sys?.sunrise && otherLocation.sys?.sunset ? 
                  getDaylightInfo(otherLocation).daylightDuration : 
                  "Est. 12h 15m"}</span>
              </div>
              <div className="current-detail-item">
                <i className="fas fa-sunset"></i>
                <span><b>Sunset</b>: {otherLocation.sys?.sunset ? 
                  getDaylightInfo(otherLocation).sunset : 
                  "Est. " + new Date(new Date().setHours(18, Math.floor(Math.random() * 30), 0, 0)).toLocaleTimeString([], {hour: 'numeric', minute:'2-digit', hour12: true})}</span>
                <span className="separator">|</span>
                <span><b>Hours of Moonlight</b>: {otherLocation.sys?.sunrise && otherLocation.sys?.sunset ? 
                  getDaylightInfo(otherLocation).nightDuration : 
                  "Est. 11h 45m"}</span>
              </div>
              <div className="current-detail-item moon-phase-item">
                <span className="moon-emoji">{getMoonPhaseIcon(calculateMoonPhase())}</span>
                <span><b>Current Moon Phase</b></span>
              </div>
            </div>
          </div>
          <div className="map-with-forecast">
            <div className="map-container">
              <LocationMap location={otherLocationData} showWeatherLayer={true} />
            </div>
            <div className="forecast-container">
              <ForecastSnippet 
                forecastData={otherForecast} 
                isLoading={forecastLoading} 
                error={forecastError} 
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="geographic-info">
        <div className="info-item">
          <div className="info-icon"><i className="fas fa-globe"></i></div>
          <div className="item-label">Distance</div>
          <div className="item-value">
            {calculateDistance()} km / {Math.round(calculateDistance() * 0.621371)} mi apart
          </div>
        </div>
        <div className="info-item">
          <div className="info-icon"><i className="fas fa-clock"></i></div>
          <div className="item-label">Time Difference</div>
          <div className="item-value">{getTimezoneDifference()}</div>
        </div>
        <div className="info-item">
          <div className="info-icon"><i className="fas fa-users"></i></div>
          <div className="item-label">Population</div>
          <div className="item-value">
            {(() => {
              // Simulate population data based on location name
              // In a real app, this would come from an API
              const yourPop = getSimulatedPopulation(yourLocation.name);
              const otherPop = getSimulatedPopulation(otherLocation.name);
              const diff = Math.abs(yourPop - otherPop);
              const larger = yourPop > otherPop ? yourFormattedName : otherFormattedName;
              
              return (
                <>
                  {formatPopulation(yourPop)} vs {formatPopulation(otherPop)}
                  <div className="info-comparison">
                    {larger} has {formatPopulation(diff)} more residents
                  </div>
                </>
              );
            })()}
          </div>
        </div>
        <div className="info-item">
          <div className="info-icon"><i className="fas fa-mountain"></i></div>
          <div className="item-label">Elevation</div>
          <div className="item-value">
            {(() => {
              // Simulate elevation data based on coordinates
              // In a real app, this would come from an API
              const yourElevation = getSimulatedElevation(
                yourLocation.coord?.lat || 0, 
                yourLocation.coord?.lon || 0
              );
              const otherElevation = getSimulatedElevation(
                otherLocation.lat || otherLocation.coord?.lat || 0, 
                otherLocation.lon || otherLocation.coord?.lon || 0
              );
              const diff = Math.abs(yourElevation - otherElevation);
              const higher = yourElevation > otherElevation ? yourFormattedName : otherFormattedName;
              
              return (
                <>
                  {yourElevation}m vs {otherElevation}m
                  <div className="info-comparison">
                    {higher} is {diff}m higher above sea level
                  </div>
                </>
              );
            })()}
          </div>
        </div>
      </div>
      
      <div className="fun-fact-container">
        <div className="fun-fact">
          <div className="fun-fact-icon">
            <i className="fas fa-lightbulb"></i>
          </div>
          <div className="fun-fact-content">
            <h3>Weather Fact</h3>
            <p>
              {generateWeatherFact(yourLocation, otherLocation)}
            </p>
          </div>
        </div>
        
        <div className="fun-fact travel-fact">
          <div className="fun-fact-icon">
            <i className="fas fa-plane"></i>
          </div>
          <div className="fun-fact-content">
            <h3>Travel Between</h3>
            <p>
              The straight-line distance between these locations is approximately {calculateDistance()} km ({Math.round(calculateDistance() * 0.621371)} mi). 
              If you were to travel directly between them at airplane cruising speed, it would take about {Math.round(calculateDistance() / 800)} hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherComparison;