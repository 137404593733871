import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { formatUSCityName } from '../services/weatherService';

// Fix for default marker icons in react-leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

// Environment variable for the API key
const API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;

const LocationMap = ({ location, zoom = 7, showWeatherLayer = true }) => {
  if (!location || !location.lat || !location.lon) {
    return <div className="map-placeholder">No location data available</div>;
  }

  const position = [location.lat, location.lon];

  return (
    <MapContainer 
      center={position} 
      zoom={zoom} 
      style={{ height: '250px', width: '100%', borderRadius: '8px' }}
    >
      <LayersControl position="topright">
        {/* Base map layer */}
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>

        {/* Satellite view */}
        <LayersControl.BaseLayer name="Satellite">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          />
        </LayersControl.BaseLayer>

        {/* Weather layers */}
        {showWeatherLayer && (
          <>
            <LayersControl.Overlay checked name="Precipitation">
              <TileLayer
                url={`https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=${API_KEY}`}
                attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>'
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Temperature">
              <TileLayer
                url={`https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=${API_KEY}`}
                attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>'
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Clouds">
              <TileLayer
                url={`https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=${API_KEY}`}
                attribution='&copy; <a href="https://openweathermap.org/">OpenWeatherMap</a>'
              />
            </LayersControl.Overlay>
          </>
        )}
      </LayersControl>

      <Marker position={position}>
        <Popup>
          {location.formattedLocation || formatUSCityName(location.name) || 'Location'} <br />
          Lat: {location.lat.toFixed(2)}, Lon: {location.lon.toFixed(2)}
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default LocationMap;